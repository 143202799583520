export const COUNTRY_TAX_ID_REG_EXP: Record<string, RegExp> = {
	BRA: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
	HKG: /^[A-Z]{1}\d{6}\(\d{1}\)$/,
	MEX: /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{2}$/,
	SGP: /^[STFG]\d{7}[A-Z]$/,
	JPN: /^\d{12}$/,
	ESP: /^[X|Y|Z]\d{7}[A-Z]$|^\d{8}[A-Z]$/,
	ITA: /^[A-Z]{3}[A-Z]{3}\d{2}[A-E][0-9]{2}[A-Z0-9]{4}[A-Z]$/,
	CHL: /^\d{7,8}-[\dK]$/,
	NGA: /^\d{8}-\d{4}$/,
	PRT: /^\d{9}$/,
	ROU: /^\d{13}$/,
	CAN: /^\d{9}$/,
	CZE: /^\d{6}\/\d{4}$/,
	DNK: /^\d{6}-\d{4}$/,
	FIN: /^\d{6}-\d{3}[A-Z0-9]$/,
	ZAF: /^\d{13}$/,
	SWE: /^\d{6}-\d{4}$|^\d{8}-\d{4}$/,
	TUR: /^[1-9]{1}[0-9]{10}$/,
};
