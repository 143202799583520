import { emailErrors } from 'views/constants';

export const BasicInformationInputsDefaultErros = {
	firstName: {
		isError: false,
		message: 'First name is invalid',
	},
	lastName: {
		isError: false,
		message: 'Last name is invalid',
	},
	phone: {
		isError: false,
		message: 'Mobile number is invalid',
	},
	email: {
		isError: false,
		message: emailErrors.REGULAR_EMAIL_ERROR,
	},
};
