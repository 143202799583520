import { IKYBFormState, IOptionType } from './stores';

export const KYB_FORM_STATE_LINEAR: IKYBFormState[] = [
	{
		label: 'Representative first name',
		placeHolder: 'Representative First Name',
		type: 'text',
		name: 'firstName',
	},
	{
		label: 'Representative last name',
		placeHolder: 'Representative Last Name',
		type: 'text',
		name: 'lastName',
	},

	{
		label: 'Name of the Business',
		placeHolder: 'Business Name',
		type: 'text',
		name: 'name',
	},
	{
		label: 'Website Url',
		placeHolder: 'https://mybusiness.com',
		type: 'text',
		name: 'website',
	},
	{
		label: 'LinkedIn Url',
		placeHolder: 'LinkedIn Url',
		type: 'text',
		name: 'linkedInUrl',
	},
	{
		label: 'Verify business using',
		placeHolder: '',
		type: 'dropdown',
		name: 'fein',
	},
	{
		label: 'Date of Birth',
		placeHolder: 'dd/mm/yyyy',
		type: 'date',
		name: 'date_of_birth',
	},
	{
		label: 'SSN',
		placeHolder: 'XXX-XX-XXXX',
		type: 'text',
		name: 'national_id_number',
	},
	{
		label: 'Phone',
		placeHolder: 'Phone',
		type: 'phone',
		name: 'phone',
	},
	{
		label: 'Street Address',
		placeHolder: 'Street',
		type: 'auto-fill',
		name: 'streetAddress',
	},
	{
		label: 'Country',
		placeHolder: 'Country',
		type: 'text',
		name: 'country',
	},
	{
		label: 'Zip Code',
		placeHolder: '12345',
		type: 'text',
		name: 'postal_code',
	},
	{
		label: 'State',
		placeHolder: 'State',
		type: 'text',
		name: 'state',
	},
	{
		label: 'City',
		placeHolder: 'City',
		type: 'text',
		name: 'city',
	},
];

export const KYB_MULTIPLE_REPRESENTATIVE = [
	{
		label: 'Name of the Parent Business',
		placeHolder: 'Parent Business Name',
		type: 'text',
		name: 'name',
		autoFillKey: 'companyName',
	},
	{
		label: 'Verify Parent Business Using',
		placeHolder: '',
		type: 'fein',
		name: 'fein',
		autoFillKey: 'fein',
	},
	{
		label: 'Website Url',
		placeHolder: 'https://mybusiness.com',
		type: 'text',
		name: 'website',
	},
	{
		label: 'Business Linkedin URL ',
		placeHolder: 'Business Linkedin URL ',
		type: 'text',
		name: 'linkedInUrl',
	},
	{
		label: 'Phone',
		placeHolder: 'Phone',
		type: 'phone',
		name: 'phone',
	},
	{
		label: 'Business Street',
		placeHolder: 'Business Street',
		type: 'auto-fill',
		name: 'streetAddress',
	},
	{
		label: 'Country',
		placeHolder: 'Country',
		type: 'text',
		name: 'country',
		autoFillKey: 'country',
	},
	{
		label: 'Zip Code',
		placeHolder: '12345',
		type: 'text',
		name: 'postal_code',
	},
	{
		label: 'State',
		placeHolder: 'State',
		type: 'text',
		name: 'state',
		autoFillKey: 'state',
	},
	{
		label: 'City',
		placeHolder: 'City',
		type: 'text',
		name: 'city',
	},
];

export const KYB_BUSINESS_VERIFICATION = [
	{
		label: 'Name of the Parent Business',
		placeHolder: 'Parent Business Name',
		type: 'text',
		name: 'companyName',
	},

	{
		label: 'Country',
		placeHolder: 'Country',
		type: 'select',
		name: 'country',
	},
	{
		label: 'State',
		placeHolder: 'State',
		type: 'stateSelect',
		name: 'state',
	},
	{
		label: 'Verify Parent Business Using',
		placeHolder: '',
		type: 'fein',
		name: 'fein',
	},
];

// @TODO: To be removed later
export const THE_KYB_PROVIDER_TOKEN: {
	stage: string;
	preprod: string;
	beta: string;
	prod: string;
} = {
	stage:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2MGQxMGIyNjljMGZiOWM5NTgwMTJiZCIsImNsaWVudF9pZCI6IjVkNzVjN2IxZTk2ZTQwOWQ5MWVmZjMwOGEzOGU0OWI1IiwiYnVzaW5lc3MiOiI2NDA1YmVhMzJlZmIyZjY4Njk5NmZhNTEiLCJpYXQiOjE3MTIxMzIyNzQsImV4cCI6MTc0MzY2ODI3NH0.qZbYRo0rZ2AZelo_5mblkM48paiAyR9H-3hEc0-_IAQ',
	preprod:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2MjY0N2M4ZjM0YmEzOTNhNWE3ZjQ3YiIsImNsaWVudF9pZCI6IjViM2NmZmM2MjdlNjRiOWY4NThjYzYxZGM5OWMxMWZiIiwiYnVzaW5lc3MiOiI2NWYyZGVmNzViMTkyNTRhNTlmOGZkZjMiLCJpYXQiOjE3MTM3ODQ3NzYsImV4cCI6MTc0NTMyMDc3Nn0.dFh25QHfZu-iZcTNyYP2Miw_bO24O45efG46f2MHkvk',
	beta: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2MmI5OTdhY2EzNzhjODA1NDRlNmE0MyIsImNsaWVudF9pZCI6IjI2ODA5YmU2NTc3ZTRhOWU4OWRiNTdkZTE1NmQzYWM5IiwiYnVzaW5lc3MiOiI2NTU2NzdmMDFiMzY1YzVhMzc5ZGVlNGQiLCJpYXQiOjE3MTQxMzMzNzAsImV4cCI6MTc0NTY2OTM3MH0.dXW1Ursz2qJboJBKAnyWjqRMvSetZPyKovZC7w4pxLk',
	prod: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2MjkwY2UyYjFjNjRkZjUzMGE5ZDMzNSIsImNsaWVudF9pZCI6IjAzMzBkODcyZjkyZDRkMDliNzU1OTY3MjZiMjFiMzg0IiwiYnVzaW5lc3MiOiI2NWU2ZWU1YTk2ZGEyYTE2YzViM2M5ZWEiLCJpYXQiOjE3MTM5NjYzMDYsImV4cCI6MTc0NTUwMjMwNn0.DY9wPvyuNiRGD5Sjh53g8I4lEdjZ73Na_3FdzX1NDfs',
};

export const BUSINESS_NUMBER_TYPE: IOptionType[] = [
	{ label: 'EIN/TIN', value: 'fein' },
];

export const COMPANY_SELECTED_MEMBER_FORM = [
	{
		label: 'Phone',
		placeHolder: 'Phone number',
		type: 'phone',
		name: 'telephone_number',
	},
	{
		label: 'Email',
		placeHolder: 'abc@gmail.com',
		type: 'text',
		name: 'email',
	},
	{
		label: 'Date of Birth',
		placeHolder: 'MM/DD/YYYY',
		type: 'date',
		name: 'date_of_birth',
	},

	{
		label: 'SSN',
		placeHolder: 'XXX-XX-XXXX',
		type: 'text',
		name: 'national_id_number',
	},
];

export const COMPANY_SELECTED_MEMBER_REPRESENTATIVE_FORM = [
	{
		label: 'Representative Type',
		placeHolder: 'Select',
		type: 'dropOption',
		name: 'type',
	},
	{
		label: 'Ownership (In Percentage)',
		placeHolder: '',
		type: 'text',
		name: 'ownershipPercentage',
	},
];

export const COMPANY_NONE_MEMBER_FORM = [
	{
		label: 'First name',
		placeHolder: 'First name',
		type: 'text',
		name: 'first_name',
	},
	{
		label: 'Last name',
		placeHolder: 'Last name',
		type: 'text',
		name: 'last_name',
	},
	{
		label: 'Phone',
		placeHolder: 'Phone number',
		type: 'phone',
		name: 'phone',
	},
	{
		label: 'Email',
		placeHolder: 'abc@gmail.com',
		type: 'text',
		name: 'email',
	},
	{
		label: 'Date of Birth',
		placeHolder: 'MM/DD/YYYY',
		type: 'date',
		name: 'date_of_birth',
	},
	{
		label: 'Tax ID Number',
		placeHolder: 'XXX-XX-XXXX',
		type: 'text',
		name: 'national_id_number',
	},
	{
		label: 'Representative Type',
		placeHolder: 'Select',
		type: 'dropOption',
		name: 'type',
	},
	{
		label: 'Ownership (In Percentage)',
		placeHolder: '',
		type: 'text',
		name: 'ownershipPercentage',
	},
];

export const customMember = {
	id: 'custom',
	name: 'custom',
	designation: 'custom',
	status: 'others',
};

export const defaultCustomMember = {
	id: 'custom',
	name: 'Member or executive not listed',
	designation: 'Add details manually',
};

export const EXIST_KYC_AML_MEMBERS_FORM = [
	{
		label: 'Phone',
		placeHolder: 'Phone number',
		type: 'phone',
		name: 'phone',
	},
	{
		label: 'Email',
		placeHolder: 'abc@gmail.com',
		type: 'text',
		name: 'email',
	},
	{
		label: 'Representative Type',
		placeHolder: 'Select',
		type: 'dropOption',
		name: 'type',
	},
	{
		label: 'Ownership (In Percentage)',
		placeHolder: '',
		type: 'text',
		name: 'ownershipPercentage',
	},
];

export const SUBSIDIARY_BUSINESS_CONSTANTS = {
	Header_Title: 'Subsidiary business/company details',
	Header_Description:
		'Please select the Subsidiary business/company you want to do Subsidiary verification for.',
	No_Subsidiaries_Message: 'No Subsidiaries match your search term.',
	Not_Select_More_Subsidiaries_Text:
		'You cannot select more than 5 Subsidiaries',
	Fetching_Subsidiaries_Text: 'We are fetching Subsidiary business/company.',
	Not_Find_Linked_Subsidiaries_Text: `We didn't find any Subsidiary companies associated with the provided business.`,
	KYB_Process_Message:
		'The KYB process for subsidiaries may take some time to complete. Please wait while it completes.',
};

export const BusinessCompanyMemberListError = {
	DOB: 'You must be at least 18 years old to access this content.',
	SSN_NINE_DIGIT:
		'The minimum length for a Social Security Number should be 9 digits.',
	SSN_FIFTEEN_DIGIT:
		'The maximum length for a Tax ID Number should be 15 digits.',
	SSN_INVALID_DIGIT: 'Invalid Social Security Number.',
	EMAIL_INVALID: 'Invalid email Id',
	PHONE_INVALID: 'Phone should be at least 8 digits',
};

export const BUSINESS_INFO_CONSTATNTS = {
	TIN: 'TIN',
	Registration: 'Registration',
	EinLabel: 'EIN/TIN',
	RegistrationLabel: 'Registration Number',
	IsUSCountry: 'United States',
	EinPlaceHolder: 'Enter EIN/TIN number',
	RegistrationPlaceHolder: 'Enter registration number',
};
export const COMPANY_OWNERSHIP_SELECT_OTPIONS = [
	{
		label: 'Owner',
		value: 'owner',
	},
	{
		label: 'Signer',
		value: 'signer',
	},
	{
		label: 'Owner & Signer',
		value: 'ownerAndSigner',
	},
];
