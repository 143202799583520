
import { atom } from "recoil";
export  const DefaultConfigManager = {
    components:{
       input:{
             isLabelTitleRequired:true,
       },
    },
    isHeaderHide:false,
    pages:{
      "term-condition":{
          hide:false
       },
      "basic-details":{
             title:"Basic Information",
             button:{
               primary:"Next",
               secondary:""
             },
             skipForm:false,
             note:"Please enter your name as it appears on your government-issued ID",
             inputStyles: {
                 firstName:{
                    width:"calc(50%  - 8px)",
                    height:"52px !important"
                 },
                 lastName:{ width:"calc(50%  - 8px)",},
                 email:{ width:"100%"},
                 phone:{width:'100%'}
             }
        },
       "success":{
           "image":"",
           "title":"You have been successfully registered",
           "description":"Please wait we are redirecting you to the home page in 1 minut"
       }	 
    } 
}
export const ConfigManagerState = atom({
    default: DefaultConfigManager,
    key:"config-manager-state"
})