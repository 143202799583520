import { IButton } from '.';

import { FC } from 'react';
import { useSharedVariables } from 'hooks';

import './button.scss';

export const Button: FC<IButton> = ({
	label,
	handleClick,
	loader,
	type,
	icon,
	height,
	disabled,
}) => {
	const { configManager } = useSharedVariables();
	const { primary, disabled: disabledStyle } =
		configManager?.components?.button ?? {};
	const style = disabled ? disabledStyle : primary;
	return (
		<button
			className={`button ${type} ${disabled ? 'button--disabled' : ''}`}
			onClick={handleClick}
			style={{ height, ...style }}
			disabled={disabled}
		>
			<>{label}</>
			{loader}
			{icon && <i className={icon} />}
		</button>
	);
};
