// export const isMobileDevice = () => {
//   var mobile = [
//     "iphone",
//     "ipad",
//     "android",
//     "blackberry",
//     "nokia",
//     "opera mini",
//     "windows mobile",
//     "windows phone",
//     "iemobile",
//   ];
//   for (var i in mobile)
//     if (
//       navigator.userAgent.toLowerCase().indexOf(mobile[i].toLowerCase()) > 0 &&
//       window.innerWidth < 500
//     )
//       return true;
//   return false;
// };
export const isMobileDevice = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);
};

export async function getPhoneModel() {
	let model = 'Unknown Device';

	// 1️⃣ Try using `navigator.userAgentData` (Modern Browsers)
	if (
		(window as any).navigator.userAgentData &&
		(window as any).navigator.userAgentData.getHighEntropyValues
	) {
		try {
			const data = await (
				window as any
			).navigator.userAgentData.getHighEntropyValues(['model']);
			if (data.model) {
				return data.model; // Exact model (if available)
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error fetching model from userAgentData:', error);
		}
	}

	// 2️⃣ Fallback to `navigator.userAgent` parsing
	const userAgent =
		navigator?.userAgent || navigator?.vendor || (window as any)?.opera;

	// Common patterns for detecting device models
	const match = userAgent.match(/\(([^)]+)\)/);
	if (match && match[1]) {
		const deviceInfo = match[1];
		const parts = deviceInfo.split('; ');
		if (parts.length > 1) {
			model = parts[1]; // Extracting model name
		}
	}

	// 3️⃣ Additional parsing for Samsung, iPhone, and other known patterns
	if (/SM-|SAMSUNG|Galaxy/.test(userAgent)) {
		const samsungMatch = userAgent.match(/(SM-\w+|Galaxy \w+)/);
		if (samsungMatch) {
			return samsungMatch[0]; // Samsung model extraction
		}
	}
	if (/iPhone|iPad|iPod/.test(userAgent)) {
		const appleMatch = userAgent.match(/(iPhone|iPad|iPod) OS (\d+)/);
		if (appleMatch) {
			return `${appleMatch[1]} (iOS ${appleMatch[2]})`;
		}
	}

	return model; // Default if nothing found
}

export const getiOSVersion = (): number | null => {
	const ua: string = navigator.userAgent ?? '';
	const iosRegex = /OS (\d+)[_.](\d+)[_.]?(\d+)?/;
	if (/iP(hone|od|ad)/.test(ua)) {
		const match = ua.match(iosRegex);
		if (match !== null) {
			const major = parseInt(match[1] ?? '0', 10);
			const minor = parseInt(match[2] ?? '0', 10);
			const patch = parseInt(match[3] ?? '0', 10);
			const versionNumber = major + minor / 10 + patch / 100;
			return versionNumber;
		}
	}
	return null;
};
