import { IInput, Input } from '@storybook';
import { useMaskedInput } from 'hooks';
import { useRef } from 'react';

export const MaskedInput = (props: IInput) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const { displayValue, viewIcon, handleChange, toggleMask } = useMaskedInput({
		initialValue: props.value as string,
		onChange: props.handleChange,
		inputRef,
	});
	return (
		<Input
			{...props}
			handleChange={handleChange}
			value={displayValue}
			inputRef={inputRef}
			suffixIcon={viewIcon}
			handleSuffixIcon={toggleMask}
		/>
	);
};
