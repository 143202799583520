import { useCallback, useEffect, useMemo } from 'react';
import { v4 } from 'uuid';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PaymentMethod, PaymentSelectorProps } from './types';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import { Loader } from '@storybook';
import {
	FINIX_BUISNESS,
	FINIX_PAYMENT_METHOD,
	PAYMENT_OPTIONS,
} from './constant';
import {
	IsLoaderShownInPaymentMethodScreenState,
	SelectedPaymentMethodState,
} from 'views/fund-investment/stores';
import useApplePay from './apple-pay/use-apple-pay';

import './payment-select.scss';
import { useNextStep, useNotification } from 'hooks';
import { useGooglePay } from './google-pay';
import useGooglePaySupport from './google-pay/hook';

export const PaymentSelector = ({
	onMethodSelect,
	handleBacktoAmoutScreen,
	error,
}: PaymentSelectorProps) => {
	const setSelectedPaymentMethod = useSetRecoilState(
		SelectedPaymentMethodState
	);
	const isLoaderShowinPaymentMethoScreen = useRecoilValue(
		IsLoaderShownInPaymentMethodScreenState
	);
	const { errorNotification } = useNotification();
	const { checkGooglePaySupport, isSupported: isGooglePayNotsupported } =
		useGooglePaySupport();
	const { sessionPayloadDetail } = useNextStep();
	const { investingAmount } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);
	const isAndroid = useMemo(() => /Android/i.test(navigator.userAgent), []);
	const { handleApplePayClick } = useApplePay();
	const { handleGooglePayClick } = useGooglePay();

	const handleSelect = useCallback(
		(method: PaymentMethod) => {
			if (method === 'apple-pay') {
				if (investingAmount > 10000) {
					errorNotification(
						'Transaction limit exceeded: Apple Pay transactions are limited to $10,000.'
					);
					handleBacktoAmoutScreen();
					return;
				}
				handleApplePayClick();
				return;
			}
			if (method === 'google-pay') {
				if (investingAmount > 2000) {
					errorNotification(
						'Transaction limit exceeded: Google Pay transactions are limited to $2,000.'
					);
					return;
				}
				handleGooglePayClick();
				return;
			}
			setSelectedPaymentMethod(method);
			onMethodSelect(method);
		},
		[
			errorNotification,
			handleApplePayClick,
			handleBacktoAmoutScreen,
			handleGooglePayClick,
			investingAmount,
			onMethodSelect,
			setSelectedPaymentMethod,
		]
	);

	const sessionDetails = useRecoilValue(SessionDetailsState);
	const { businessId = '' } = sessionDetails ?? {};

	useEffect(() => {
		checkGooglePaySupport();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filteredOptions = useMemo(() => {
		const options = FINIX_BUISNESS.includes(businessId ?? '')
			? FINIX_PAYMENT_METHOD
			: PAYMENT_OPTIONS;

		// Filter out 'apple-pay' for Android
		const payMethodList = isAndroid
			? options.filter(option => option.id !== 'apple-pay')
			: options;

		// Further filter out 'google-pay' if not supported
		return !isGooglePayNotsupported
			? payMethodList.filter(option => option.id !== 'google-pay')
			: payMethodList;
	}, [businessId, isAndroid, isGooglePayNotsupported]);

	const renderPatmentSelectorBody = useMemo(() => {
		if (isLoaderShowinPaymentMethoScreen) {
			return (
				<div className="payment-selector__loader--wrapper">
					<Loader />
					<div>We are processing your transactions. please wait.</div>
				</div>
			);
		}
		return (
			<div className="payment-selector__option--wrapper">
				{(filteredOptions ?? []).map(option => (
					<div
						key={v4()}
						onClick={() => handleSelect(option.id)}
						className="payment-selector__card"
					>
						<div className="payment-selector__icon">
							<i className={option.icon}></i>
						</div>
						<div className="payment-selector__right--section">
							<div className="payment-selector__card-heading--wrapper">
								<div className="payment-selector__card--heading">
									{option.label}
								</div>
								<div className="payment-selector__card--headarrow">
									<i className="ri-arrow-right-line"></i>
								</div>
							</div>
							<div className="payment-selector__label">
								{option.description}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}, [filteredOptions, handleSelect, isLoaderShowinPaymentMethoScreen]);

	const getSelectorClasses = useMemo(
		() =>
			`payment-selector ${isLoaderShowinPaymentMethoScreen ? 'payment-selector__wrapper-height' : ''}`,
		[isLoaderShowinPaymentMethoScreen]
	);

	return (
		<div className={getSelectorClasses}>
			{renderPatmentSelectorBody}
			{error && (
				<div className="payment-selector__error" role="alert">
					{error}
				</div>
			)}
		</div>
	);
};
