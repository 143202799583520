import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { pointGen } from 'utils/format-text';

interface UseMaskedInputProps {
	initialValue?: string;
	onChange?: ({ target }: any) => void;
	inputRef: React.RefObject<HTMLInputElement>;
}

export const useMaskedInput = ({
	initialValue = '',
	onChange,
	inputRef,
}: UseMaskedInputProps) => {
	// State for managing masked and unmasked values
	const [maskedValue, setMaskedValue] = useState<string>(initialValue);
	const [unmaskedValue, setUnmaskedValue] = useState<string>(initialValue);
	const [isMasked, setIsMasked] = useState<boolean>(true);

	// Apply masking effect with debounce
	useEffect(() => {
		const timer = window.setTimeout(() => {
			if (inputRef.current) {
				const cursorPos = inputRef.current.selectionStart;
				setMaskedValue(pointGen(maskedValue?.length || 0));

				// Restore cursor position
				inputRef.current.selectionStart = cursorPos;
				inputRef.current.selectionEnd = cursorPos;
			}
		}, 800);

		return () => window.clearTimeout(timer);
	}, [maskedValue, inputRef]);

	// Handle input changes
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const cursorPos = e.target.selectionStart ?? 0;
			let inputValue = e.target.value;

			if (isMasked) {
				// Handle masked input logic
				const newUnmaskedValue = (() => {
					const prevValue = unmaskedValue;
					const newValue = inputValue;
					const addedChars = newValue.length - prevValue.length;

					if (addedChars > 0) {
						const addedText = newValue.slice(cursorPos - addedChars, cursorPos);
						return (
							prevValue.slice(0, cursorPos - addedChars) +
							addedText +
							prevValue.slice(cursorPos - addedChars)
						);
					} else {
						const deleteCount = Math.abs(addedChars);
						return (
							prevValue.slice(0, cursorPos) +
							prevValue.slice(cursorPos + deleteCount)
						);
					}
				})();
				inputValue = newUnmaskedValue;
			}

			// Update masked display
			if (inputValue.length === 0) {
				setMaskedValue('');
			} else if (inputValue.length > unmaskedValue.length) {
				setMaskedValue(() => {
					const length = inputValue?.length ?? 0;
					const newText = pointGen(length).split('');

					if (inputValue && typeof cursorPos === 'number') {
						newText[cursorPos - 1] = inputValue[cursorPos - 1] ?? '';
					}
					return newText.join('');
				});
			} else {
				setMaskedValue(pointGen(inputValue?.length));
			}
			setUnmaskedValue(inputValue);
			onChange?.({
				target: {
					value: inputValue,
					selectionStart: e.target.selectionStart,
					name: e.target.name,
				},
			});
		},
		[isMasked, unmaskedValue, onChange]
	);

	// Toggle mask visibility
	const toggleMask = useCallback(() => {
		setIsMasked(prev => !prev);
	}, []);

	// Compute display value
	const displayValue = useMemo(
		() => (isMasked ? maskedValue : unmaskedValue),
		[isMasked, maskedValue, unmaskedValue]
	);

	// Compute view icon
	const viewIcon = useMemo(
		() => (isMasked ? 'ri-eye-line' : 'ri-eye-off-line'),
		[isMasked]
	);

	return {
		displayValue,
		viewIcon,
		handleChange,
		toggleMask,
		isMasked,
	};
};
