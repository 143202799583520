import { Fragment, useEffect, useState } from 'react';
import { Loader } from '@storybook';

import { PATRIOT_ACT_ONETRUST_TOKEN } from 'constants/common';

export const PatriotAct = () => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const OneTrust = (window as any).OneTrust;
		OneTrust.NoticeApi.Initialized.then(function () {
			OneTrust.NoticeApi.Initialized.then(function () {
				OneTrust.NoticeApi.LoadNotices([PATRIOT_ACT_ONETRUST_TOKEN.url]);
			});
		});
		setTimeout(() => setIsLoading(false), 500);
	}, [isLoading]);

	return (
		<Fragment>
			{isLoading && (
				<div className="loader-wrapper">
					<Loader className="loader-blue" dimension={40} />
				</div>
			)}

			<div
				style={{ display: isLoading ? 'none' : 'block' }}
				id={PATRIOT_ACT_ONETRUST_TOKEN.tokenId}
				className="otnotice"
			></div>
		</Fragment>
	);
};
