import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { REACT_APP_GOOGLE_PAY_ENV_TYPE } from 'envs';
import { AccessTokenState } from 'states';
import { useNextStep, useNotification, useTokenSession } from 'hooks';

import { allowedCardAuthMethods, allowedCardNetworks } from './constants';
import { FundNameState } from 'hooks/use-next-step/stores';
import { IsLoaderShownInPaymentMethodScreenState } from 'views/fund-investment/stores';

export const useGooglePay = () => {
	// Hooks
	const { errorNotification, warningNotification, successNotification } =
		useNotification();
	const { postTokenSession } = useTokenSession();
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const fundNameState = useRecoilValue(FundNameState);
	const setLoaderShowinPaymentMethoScreen = useSetRecoilState(
		IsLoaderShownInPaymentMethodScreenState
	);

	const {
		sessionDetails,
		sessionPayloadDetail,
		setSessionDetails,
		handleNext,
	} = useNextStep();

	const { nodes } = sessionDetails ?? {};
	const { paymentProviders } = nodes ?? {};

	const finixProviderData = useMemo(() => {
		return (paymentProviders ?? []).find(
			(item: { providerName: string }) => item?.providerName === 'FINIX'
		);
	}, [paymentProviders]);

	const { currentAction, investingAmount, fundName, sessionId } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const finishPayment = useCallback(async () => {
		const payload = {
			nodeId: currentAction._id,
			actions: [{}],
		};
		// COMPLEX_SESSION patch api
		const res = await postTokenSession({ payload, code: sessionCode });
		if (res?.statusCode === 200) {
			const paymentResponse = { ...res };
			delete paymentResponse.statusCode;
			setSessionDetails(prev => ({
				...prev,
				nodes: paymentResponse,
			}));
			setLoaderShowinPaymentMethoScreen(false);
			handleNext();
		} else {
			setLoaderShowinPaymentMethoScreen(false);
			errorNotification(
				res.message ?? 'Something Went Wrong. Please try another method'
			);
		}
	}, [
		currentAction._id,
		errorNotification,
		handleNext,
		postTokenSession,
		sessionCode,
		setLoaderShowinPaymentMethoScreen,
		setSessionDetails,
	]);

	const processPayment = useCallback(
		(paymentData: google.payments.api.PaymentData) => {
			const paymentToken =
				paymentData?.paymentMethodData?.tokenizationData?.token;
			if (paymentToken) {
				postTokenSession({
					payload: {
						nodeId: currentAction._id,
						sessionId: sessionId,
						thirdPartyToken: paymentToken,
						amount: (investingAmount ?? 0).toString(),
						paymentMethod: 'GOOGLE_PAY',
						paymentProvider: 'FINIX',
					},
					nodeId: currentAction?._id,
					type: 'payment',
				})
					.then(resp => {
						if (
							resp?.statusCode === 200 ||
							/\bhold\b/.test(resp?.message ?? '')
						) {
							if (/\bhold\b/.test(resp?.message)) {
								warningNotification(resp?.message);
							} else {
								successNotification('Transaction successfully done !');
							}
							finishPayment();
						} else {
							setLoaderShowinPaymentMethoScreen(false);
							errorNotification(
								'Something went wrong!. Please try another method'
							);
						}
					})
					.catch(error => {
						setLoaderShowinPaymentMethoScreen(false);
						// eslint-disable-next-line no-console
						console.error('Error:', error);
					});
			}
		},
		[
			currentAction._id,
			errorNotification,
			finishPayment,
			investingAmount,
			postTokenSession,
			sessionId,
			setLoaderShowinPaymentMethoScreen,
			successNotification,
			warningNotification,
		]
	);

	const handleGooglePayClick = useCallback(() => {
		const googlePayClient = new google.payments.api.PaymentsClient(
			REACT_APP_GOOGLE_PAY_ENV_TYPE === 'PRODUCTION'
				? { environment: REACT_APP_GOOGLE_PAY_ENV_TYPE }
				: {}
		);
		const paymentDataRequest: google.payments.api.PaymentDataRequest = {
			apiVersion: 2,
			apiVersionMinor: 0,
			allowedPaymentMethods: [
				{
					type: 'CARD',
					parameters: {
						allowedAuthMethods: allowedCardAuthMethods,
						allowedCardNetworks: allowedCardNetworks,
						billingAddressRequired: false,
					},
					tokenizationSpecification: {
						type: 'PAYMENT_GATEWAY',
						parameters: {
							gateway: 'finix',
							gatewayMerchantId: finixProviderData?.details?.gatewayMerchantId,
						},
					},
				},
			],
			transactionInfo: {
				totalPriceStatus: 'FINAL',
				totalPrice: (investingAmount ?? 0).toString(),
				currencyCode: 'USD',
				countryCode: 'US',
			},
			merchantInfo: {
				merchantName:
					finixProviderData?.details?.googlePayMerchantName ||
					fundName ||
					fundNameState ||
					'your Payment',
				merchantId: finixProviderData?.details?.googlePayMerchantId,
			},
		};

		googlePayClient
			.loadPaymentData(paymentDataRequest)
			.then(paymentData => {
				setLoaderShowinPaymentMethoScreen(true);
				processPayment(paymentData);
			})
			.catch(error => {
				errorNotification('Transaction declined ');
				setLoaderShowinPaymentMethoScreen(false);
				// eslint-disable-next-line no-console
				console.error('Error loading payment data:', error);
			});
	}, [
		errorNotification,
		finixProviderData?.details?.gatewayMerchantId,
		finixProviderData?.details?.googlePayMerchantId,
		finixProviderData?.details?.googlePayMerchantName,
		fundName,
		fundNameState,
		investingAmount,
		processPayment,
		setLoaderShowinPaymentMethoScreen,
	]);

	return {
		handleGooglePayClick,
	};
};
