import { useState, useCallback } from 'react';
import { allowedCardAuthMethods, allowedCardNetworks } from './constants';
import { REACT_APP_GOOGLE_PAY_ENV_TYPE } from 'envs';

const useGooglePaySupport = () => {
	const [isSupported, setIsSupported] = useState<boolean | null>(null);

	const checkGooglePaySupport = useCallback(async () => {
		try {
			const googlePayClient = new google.payments.api.PaymentsClient(
				REACT_APP_GOOGLE_PAY_ENV_TYPE === 'PRODUCTION'
					? { environment: REACT_APP_GOOGLE_PAY_ENV_TYPE }
					: {}
			);

			const isReadyToPayRequest = {
				apiVersion: 2,
				apiVersionMinor: 0,
				allowedPaymentMethods: [
					{
						type: 'CARD',
						parameters: {
							allowedAuthMethods: allowedCardAuthMethods,
							allowedCardNetworks: allowedCardNetworks,
						},
					},
				],
			};

			const response = await googlePayClient.isReadyToPay(isReadyToPayRequest);
			setIsSupported(response.result);
			return response.result;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Google Pay isReadyToPay error:', error);
			setIsSupported(false);
			return false;
		}
	}, []);

	return { isSupported, checkGooglePaySupport };
};

export default useGooglePaySupport;
