import { Button, RadioCardSelection } from '@storybook';
import { FC } from 'react';

interface IRadioSelect {
	defaultValue: any;
	onChange: any;
	settings: any[];
	handleNext: VoidFunction;
	handleBack: VoidFunction;
	isNextDisabled?: boolean;
	labelNext?: string;
	isBackDisabled?: boolean;
}
export const RadioSelect: FC<IRadioSelect> = ({
	defaultValue,
	onChange,
	settings,
	handleNext,
	handleBack,
	isNextDisabled = false,
	labelNext = 'Next',
	isBackDisabled = false,
}) => {
	return (
		<div className="radio-select-wrapper">
			<RadioCardSelection
				checkedValue={defaultValue}
				cardValues={settings ?? []}
				handleOnClick={onChange}
			/>
			<div className="button-wrapper">
				<Button
					label={labelNext}
					handleClick={handleNext}
					type="button__filled button__filled--primary button__large button__block "
					disabled={isNextDisabled}
				/>
				<Button
					label="Back"
					handleClick={handleBack}
					type="button__filled button__filled--secondary button__large button__block"
					disabled={isBackDisabled}
				/>
			</div>
		</div>
	);
};
