type MessageData = {
	type?: string;
	value?: boolean;
	isError?: boolean;
};

type EventHandler = (data: any) => void;

class EventManagerClass {
	complete: EventHandler = () => {};
	change: EventHandler = () => {};

	init(onComplete: EventHandler, onChange: EventHandler): void {
		this.complete = onComplete;
		this.change = onChange;
	}

	onComplete(data: any): void {
		this.complete(data);
	}

	onChange(data: any): void {
		this.change(data);
	}

	async waitForMessageEvent(
		eventName: string,
		timeout = 3000
	): Promise<MessageData> {
		return new Promise(resolve => {
			const listener = (event: MessageEvent) => {
				const { data } = event;
				if (data?.type === eventName && data?.value === true) {
					window.removeEventListener('message', listener);
					resolve(data);
				}
			};

			setTimeout(() => {
				window.removeEventListener('message', listener);
				resolve({ isError: false });
			}, timeout);

			window.addEventListener('message', listener);
		});
	}
}

const EventManager = new EventManagerClass();
export default EventManager;
