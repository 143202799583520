import { SurveyQuestionElementBase } from 'survey-react-ui';

import { CSSProperties } from 'react';
import { Quantity } from './quantity-question';

export class QuantitySelectorQuestion extends SurveyQuestionElementBase {
	constructor(props: any) {
		super(props);
		this.state = {
			value: (this.question as any)?.jsonObj?.defaultValue ?? null,
		};
	}
	get question() {
		return this.questionBase;
	}
	get value() {
		return this.question?.value;
	}
	get disableAlpha() {
		return this.question.disableAlpha;
	}
	get type() {
		return this.question.colorPickerType;
	}
	handleDetails = (data: number) => {
		if (data === 0) {
			this.question.value = this.question?.value ?? 1;
		} else {
			this.question.value = data ?? this.question.value;
		}
	};
	// Support the read-only and design modes
	get style() {
		return this.question.getPropertyValue('readOnly') ||
			this.question.isDesignMode
			? { pointerEvents: 'none' }
			: undefined;
	}

	renderElement() {
		return (
			<div style={this.style as CSSProperties}>
				<Quantity
					handleDetails={this.handleDetails}
					defaultValue={this.question?.value || 1}
					isDisabled={this.question.isReadOnly}
				/>
			</div>
		);
	}
}
