import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	parsePhoneNumberFromString,
	AsYouType,
	CountryCode as CountryType,
	isValidNumber,
} from 'libphonenumber-js';
import countries from '@storybook/country-code/json/country-codes.json';
import { fetchCountryCodeFromPhone } from '@storybook/country-code';
import { IPhoneNumber } from 'states';
import { Input } from './input';

/**
 * Interface for the props of the CountryMobileNumber component.
 * @param {function} Props.handleChange - Callback function invoked when the phone number changes, receiving an object with `countryCode` and `phone`.
 * @param {function} Props.handleBlur - Callback function invoked when the phone number blur, receiving an object with `countryCode` and `phone`.
 * @param {string} [Props.defaultNumber] - The default phone number to be displayed in the input field.
 * @param {string | number} [Props.defaultCountryCode] - The default country code to be used in the input field.
 * @param {boolean} [Props.isCountryCodeVisible] - Flag indicating whether the country code selector is visible.
 * @param {function} Props.handleChangeCountry - Callback function invoked when the country code changes, receiving the new country code.
 * @param {string} [Props.errorMessage] - The error message to be displayed when there's an error.
 * @param {boolean} [Props.isError] - Flag indicating whether there's an error in the input.
 * @param {function} [Props.handleClearField] - Callback function invoked when the input field is cleared.
 * @param {boolean} [Props.disabled] - Flag indicating whether the input field is disabled.
 * @param {boolean} [Props.isRequired] - Flag indicating whether the input field is required.
 * @param {function} [Props.onKeyDown] - Callback function invoked on key down events in the input field.
 * @param {function} [Props.onPaste] - Callback function invoked on paste events in the input field.
 * @param {string} [Props.className] - Additional CSS classes for styling the component.
 */

interface IInput {
	label?: string;
	handleChange: (e: IPhoneNumber) => void;
	handleBlur?: () => void;
	defaultNumber?: string;
	defaultCountryCode?: string | number;
	isCountryCodeVisible?: boolean;
	handleChangeCountry: (e: string | number) => void;
	errorMessage?: string;
	isError?: boolean;
	handleClearField?: () => void;
	disabled?: boolean;
	isRequired?: boolean;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
	className?: string;
	handleValidation?: (isValid: boolean) => void;
	phoneNumberDisabled?: string;
	autoComplete?: string;
	isReadOnly?: boolean;
}
const Phone_Max_Number = 12;

export const CountryMobileNumber: FC<IInput> = ({
	defaultNumber = '',
	defaultCountryCode = '+1',
	handleValidation = () => ({}),
	handleBlur = () => ({}),
	phoneNumberDisabled,
	autoComplete,
	isReadOnly,
	...props
}) => {
	const { handleChange, handleChangeCountry } = props;
	const [inputCountryCode, setInputCountryCode] = useState<string | number>(
		defaultCountryCode
	);
	const [onceGetError, setOnceGetError] = useState(false);

	const [temporaryPhoneNumber, setTemporaryPhoneNumber] =
		useState<string>(defaultNumber);
	const [validationError, setValidationError] = useState<string | null>(null);
	const [touched, setTouched] = useState<boolean>(false);

	useEffect(() => {
		setTemporaryPhoneNumber(defaultNumber);
		setInputCountryCode(defaultCountryCode);
	}, [defaultCountryCode, defaultNumber]);

	const onHandleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (phoneNumberDisabled) {
				setOnceGetError(true);
			}
			const { value } = e.target;
			const phone = value.replace(/\D/g, '');
			setTouched(true);

			if (value?.startsWith('+')) {
				const fetchedCountryCode = fetchCountryCodeFromPhone(value);
				if (fetchedCountryCode) {
					const newPhone = value
						?.replace(fetchedCountryCode, '')
						?.replace(/\D/g, '');
					setInputCountryCode(fetchedCountryCode);
					handleChange({
						countryCode: fetchedCountryCode,
						phone: newPhone.replace(/\D/g, ''),
					});
					setTemporaryPhoneNumber(newPhone);
				}
			} else if (phone.length < Phone_Max_Number) {
				handleChange({
					countryCode: inputCountryCode,
					phone: value.replace(/\D/g, ''),
				});
				if (value.length < 6) {
					setTemporaryPhoneNumber(value.replace(/\D/g, ''));
				} else {
					setTemporaryPhoneNumber(value);
				}
			}
		},
		[phoneNumberDisabled, handleChange, inputCountryCode]
	);

	const getPhoneNumber = useMemo(() => {
		let countryLabel: CountryType = 'US';

		const countryObj = countries.find(item => item.label === inputCountryCode);

		if (countryObj) countryLabel = countryObj.code as CountryType;

		let formattedNumber = '';
		const phoneNumber = parsePhoneNumberFromString(
			temporaryPhoneNumber,
			countryLabel
		);
		if (temporaryPhoneNumber.length > 6) {
			formattedNumber = new AsYouType(countryLabel).input(temporaryPhoneNumber);
			if (phoneNumber && phoneNumber.isValid()) {
				formattedNumber = phoneNumber.formatNational();
				if (formattedNumber.startsWith('0')) {
					formattedNumber = formattedNumber.slice(1);
				}
				return formattedNumber.replace(
					`+${phoneNumber.countryCallingCode} `,
					''
				);
			}
		} else {
			formattedNumber = temporaryPhoneNumber;
		}
		return formattedNumber;
	}, [inputCountryCode, temporaryPhoneNumber]);

	useEffect(() => {
		let countryLabel: CountryType = 'US';
		const countryObj = countries.find(item => item.label === inputCountryCode);
		if (countryObj) countryLabel = countryObj.code as CountryType;

		const phoneNumber = parsePhoneNumberFromString(
			temporaryPhoneNumber,
			countryLabel
		);
		if (phoneNumber && phoneNumber.isValid()) {
			setValidationError(null);
			handleValidation(true);
		} else if (touched) {
			setValidationError(`Mobile number is invalid for ${countryObj?.value}`);
			handleValidation(false);
		}
	}, [handleValidation, inputCountryCode, temporaryPhoneNumber, touched]);

	const onHandleBlur = useCallback(() => {
		handleBlur();
		setTouched(true);
		let countryLabel: CountryType = 'US';

		const countryObj = countries.find(item => item.label === inputCountryCode);

		if (countryObj) countryLabel = countryObj.code as CountryType;

		const phoneNumber = parsePhoneNumberFromString(
			temporaryPhoneNumber,
			countryLabel
		);

		if (phoneNumber && phoneNumber.isValid()) {
			setValidationError(null);
		} else if (touched) {
			setValidationError(`Mobile number is invalid for ${countryObj?.value}`);
		}
	}, [handleBlur, temporaryPhoneNumber, touched, inputCountryCode]);

	const hasRunOnceRef = useRef(false);

	const isValidApplePhoneNumber = useMemo(() => {
		// Only run this block once
		if (!hasRunOnceRef.current && phoneNumberDisabled) {
			setTouched(true);
			hasRunOnceRef.current = true;
			let countryLabel: CountryType = 'US';
			const countryObj = countries.find(
				item => item.label === inputCountryCode
			);
			if (countryObj) countryLabel = countryObj.code as CountryType;

			// Only attempt to validate if phoneNumberDisabled is defined and has length
			if (phoneNumberDisabled?.length > 0) {
				return isValidNumber(phoneNumberDisabled, countryLabel) ?? true;
			}
		}
		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneNumberDisabled, inputCountryCode]);

	const onHandleChangeCountry = useCallback(
		(e: { label: string | number }) => {
			setInputCountryCode(e.label);
			handleChangeCountry(e.label);
		},
		[handleChangeCountry]
	);

	return (
		<Input
			label="Mobile Number"
			{...props}
			handleChange={onHandleChange}
			handleBlur={onHandleBlur}
			value={getPhoneNumber}
			inputType="text" //keep type text as number is not giving suggestions for auto fill will use regex to accept number
			placeholder={``}
			isCountryCodeVisible={true}
			autoComplete={autoComplete ? autoComplete : 'tel'}
			inputName="phone"
			countryCode={inputCountryCode as string}
			handleChangeCountry={onHandleChangeCountry}
			errorMessage={validationError || props.errorMessage}
			isError={!!validationError || props.isError}
			disabled={!validationError && isValidApplePhoneNumber && !onceGetError}
			readOnly={!onceGetError && !validationError && isReadOnly}
		/>
	);
};
